import '@babel/polyfill';
import '@accessible360/accessible-slick';
import 'object-fit-images';

import accordion from './object/accordion';
import inlineVideo from './object/video';
import column from './object/column';
import instagram from './object/instagram';
import twitterLoader from './object/twitter';
import facultyTable from './object/faculty-table';
import catalogProgram from './object/catalog-program';
import carousel from './object/carousel';
import programFinder from './object/program-finder';
import calendar from './object/calendar';
import catalog from './object/catalog';
import tableScroll from './object/scroll-table';
import scrollbarWidth from './object/scrollbar-width';
import alertNotifications from './object/alert-notifications';
import homeHeroVideo from './object/home-hero-video';
import heroVideo from './object/hero-video';
import sectionNav from './object/section-nav';
import header from './interface/header';
import verticalCarousel from './object/vertical-carousel';
import backToTopButton from './object/back-to-top-button';
import stickyCta from './object/sticky-cta';
import quoteTabs from './object/quote-tabs';
import featuredVideo from './object/featured-video';
import toggleGallery from './object/toggle-gallery';
import parallaxGallery from './object/parallax-gallery';
import featuredCarousel from './object/featured-carousel';
import studentCallout from './object/student-callout';
import relatedPrograms from './object/related-programs';
import bottomArrow from './util/bottom-arrow';
import studentLife3Col from './object/student-life-3col';

import lastWord from './util/last-word';
import scrollTrigger from './util/scroll-trigger';
import focusTriggersScrollEvent from './util/focus-triggers-scroll-event';
import equalHeight from './util/equalHeight';

window.$ = require('jquery');
window.jQuery = require('jquery');
const objectFitImages = require('object-fit-images');

$(() => {
  scrollbarWidth.init();
  accordion.init();
  inlineVideo.init();
  //instagramLoader.init();
  instagram.init();
  twitterLoader.init();
  facultyTable.init();
  calendar.init();
  catalogProgram.init();
  carousel.init();
  programFinder.init();
  catalog.init();
  tableScroll.init();
  alertNotifications.init();
  sectionNav.init();
  header.init();
  backToTopButton.init();
  objectFitImages();
  homeHeroVideo.init();
  heroVideo.init();
  verticalCarousel.init();
  stickyCta.init();
  quoteTabs.init();
  featuredVideo.init();
  toggleGallery.init();
  parallaxGallery.init();
  featuredCarousel.init();
  studentCallout.init();
  relatedPrograms.init();
  bottomArrow.init();
  studentLife3Col.init();

  lastWord.init();
  scrollTrigger.init();
  focusTriggersScrollEvent.init();
  equalHeight.init();

  // Run after instagram and others have loaded
  $(window).on('load', () => {
    $('body').addClass('content-loaded');
  });

  $(window).on('load resize', () => {
    setTimeout(() => {
      column.init();
    }, 100);
  });
});
