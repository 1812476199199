/**
 * Related Programs display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if Related Programs is present
 */

const relatedPrograms = (() => {
  function render() {
    $(window).on('load resize', () => {
      if ($(window).width() > 699 && $(window).width() < 1024) {
        $('.related-programs').each((i, elem) => {
          let maxHeight = 0;
          const $items = $(elem).find('.related-programs__card');

          $items.each((i, elem) => {
            if ($(elem).height() > maxHeight) {
              maxHeight = $(elem).height();
            }
          });

          $items.css({'height': maxHeight});
        });
      }
      else {
        $('.related-programs__card').removeAttr('style');
        return;
      }
    });
  }

  const init = () => {
    if (!$('.related-programs').length) {
      return;
    }

    render();
  };

  return {
    init,
  };
})();

export default relatedPrograms;