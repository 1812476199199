const toggleGallery = (() => {
  const init = () => {
    const $galleriesSections = $('.toggle-gallery__section');

    const resetColumnPosition = ($column) => {
      $column.removeAttr('style');
    }

    const setColumnPosition = ($window, $parallaxColumnsBox, $column) => {
      const heightDelta = $column.outerHeight() - $parallaxColumnsBox.outerHeight() - ($(window).height() / 2);
      let scrollDelta = ($window.scrollTop() + ($window.height() * 0.1)) - $parallaxColumnsBox.offset().top;
      scrollDelta = scrollDelta < 20 ? 0 : scrollDelta < $parallaxColumnsBox.outerHeight() ? scrollDelta : $parallaxColumnsBox.outerHeight();
      scrollDelta = scrollDelta / $parallaxColumnsBox.outerHeight();
      $column.css('transform', `translateY(${(heightDelta * (scrollDelta * 0.3))}px) scale(1.6)`);
    }

    $galleriesSections.each((index, elem) => {
      const $parallaxColumnsBox = $(elem);
      const $column = $(elem).find('.toggle-gallery__intro-img > img');


      setColumnPosition($(window), $parallaxColumnsBox, $column);

      $(window).on('scroll', () => {
        if ($(window).width() >= 700) {
          setColumnPosition($(window), $parallaxColumnsBox, $column);
        }
        else {
          resetColumnPosition($column);
        }
      });
    });

    $galleriesSections.each((index, gallerySection) => {
      const $section = $(gallerySection);
      const $cardsSubtitles = $section.find('.toggle-gallery__card-subtitle');
      const $radioBurtons = $section.find('.actions-box__input input');

      const setSubtitleHeight = () => {
        $cardsSubtitles.each((index, subtitle) => {
          $(subtitle).closest('.toggle-gallery__card').css('--subtitle-height', `${$(subtitle).outerHeight()}px`);
        });
      }

      setSubtitleHeight();

      $(window).on('load resize', () => {
        setSubtitleHeight();
      });

      $radioBurtons.on('change', (e) => {
        let imageDelay = 0;
        let delayDuration = 300;
        let $block = $(e.target).closest('.toggle-gallery__section');

        if ($(window).width() > 699) {
          const $count = $('.toggle-gallery__gallery-box--on .toggle-gallery__card').length;
          imageDelay = (150 * $count);
          delayDuration = (150 * $count) + 300;
        }

        $('.toggle-gallery__gallery-box').addClass('js-fade-out');

        setTimeout(function () {
          $('.toggle-gallery__intro-img').addClass('js-fade-out');
        }, imageDelay);

        if ($(window).width() <= 699) {
          setTimeout(function () {
            $('html, body').animate(
              {
                scrollTop: $block.offset().top,
              },
              0);
          }, delayDuration);

          setTimeout(function () {
            const anchor = $block.find('.toggle-gallery__card:visible').eq(0).find('a')
            anchor.focus();
          }, delayDuration + 100);
        }

        setTimeout(function () {
          $('.toggle-gallery__gallery-box, .toggle-gallery__intro-img').removeClass('js-fade-out');
          const activeGalleryIndex = $section.find('.actions-box__input input:checked').attr('date-toggle-gallery');
          $section.find('[date-toggle-gallery-active]').hide();
          $section.find('.toggle-gallery__gallery-box[date-toggle-gallery-active]').attr('aria-hidden', true);
          $section.find(`.toggle-gallery__gallery-box[date-toggle-gallery-active="${activeGalleryIndex}"]`).attr('aria-hidden', false);

          $section
            .find(`[date-toggle-gallery-active=${activeGalleryIndex}]`)
            .show();

          setSubtitleHeight();
        }, delayDuration);
      });
    });
  }

  return {
    init,
  };
})();

export default toggleGallery;
