const featuredVideo = (() => {
  const init = () => {
    const ANIMATION_SPEED = 300;
    const DISABLE_SCROLL_CLASS_NAME = 'js-fixed';
    const $window = $(window);
    const $body = $('body');
    const $featuredVideoSections = $('.featured-video');

    $featuredVideoSections.each((index, featuredVideoSection) => {
      const $featuredVideoSection = $(featuredVideoSection);
      const $videoModal = $featuredVideoSection.find('.featured-video__video-modal');
      const $videoModalOpener = $featuredVideoSection.find('.featured-video__open-video-modal');
      const $videoModalClose = $featuredVideoSection.find('.featured-video__close-video-modal');
      const $featuredVideoCaption = $featuredVideoSection.find('.featured-video__caption');
      const videoPlayer = $featuredVideoSection.find('.featured-video__video')[0];

      $window.on('resize load', () => {
        $featuredVideoSection.css('--caption-height', `${$featuredVideoCaption.outerHeight()}px`);
      });
  
      $videoModalOpener.on('click', () => {
        $body.addClass(DISABLE_SCROLL_CLASS_NAME);
        $videoModal.fadeIn(ANIMATION_SPEED).attr('aria-hidden', false);
        $($videoModalOpener, $videoModalClose).attr('aria-expanded', true);
        $('.modal-content').focus();
        videoPlayer.play();
      });
  
      $videoModalClose.on('click', () => {
        $body.removeClass(DISABLE_SCROLL_CLASS_NAME);
        $videoModal.fadeOut(ANIMATION_SPEED).attr('aria-hidden', true);
        $($videoModalOpener, $videoModalClose).attr('aria-expanded', false);
        $('.featured-video__open-video-modal').focus();
        videoPlayer.pause();
      });

      const tabOne = $('.modal-content');
      const tabTwo = $('.featured-video__close-video-modal');
  
      tabOne.on('keydown', e => {
        if (e.key === 'Tab' && e.shiftKey) {
          e.preventDefault();
          tabTwo.focus();
        }
      });
  
      tabTwo.on('keydown', e => {
        if (e.key === 'Tab' && !e.shiftKey) {
          e.preventDefault();
          tabOne.focus();
        }
      });
      
      
    });
  }

  return {
    init,
  };
})();

export default featuredVideo;
