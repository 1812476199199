/**
 * Student Callout display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if Student Callout is present
 */

const studentCallout = (() => {
  const setColumnPosition = ($window, $parallaxColumnsBox, $column) => {
    const heightDelta = $column.outerHeight() - $parallaxColumnsBox.outerHeight();
    let scrollDelta = ($window.scrollTop() + ($window.height() * 0.5)) - $parallaxColumnsBox.offset().top;
    scrollDelta = scrollDelta / $parallaxColumnsBox.outerHeight();
    $column.css('top', `calc(50% + ${(heightDelta * (scrollDelta * 1.0))}px)`);
  }

  const render = () => {
    $('.student-callout').each((index, elem) => {
      const $parallaxColumnsBox = $(elem);
      const $column = $(elem).find('.img-container img');


      setColumnPosition($(window), $parallaxColumnsBox, $column);

      $(window).on('scroll', () => {
        setColumnPosition($(window), $parallaxColumnsBox, $column);
      });
    });
  }

  const init = () => {
    if (!$('.student-callout').length) {
      return;
    }

    render();
  };

  return {
    init,
  };
})();
 
export default studentCallout;