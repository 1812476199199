/* global Vue */

function getFilterValues(_this, property) {
  /**
   * return an array of all the values from a certain property,
   * for getting all the filters we need in the sidebar
   */
  return _this.items.reduce((accumulator, currentValue) => {
    if ( currentValue[property]) {
      currentValue[property].forEach((item) => {
        if (accumulator.indexOf(item) === -1) {
          accumulator.push(item);
        }
      });
     
    }
    return accumulator;
   
  }, []);
}

export default {
  init() {
    if (!document.getElementById('program-list')) {
      return;
    }

    const dataUrl = document
      .getElementById('program-list')
      .getAttribute('data-url');

    const { createApp } = Vue;

    createApp({
      data() {
        return {
          items: [],
          sortBy: 'department',
          currType: ['program_type_all'],
          currDivision: ['program_division_all'],
          currLocation: ['program_location_all'],
          showFilters: false,
          resultBarHeigth: 0,
        };
      },

      created() {
        function getQueryVariable() {
          const query = window.location.search.substring(1);

          if (query !== '') {
            const vars = query.split('&');
            const pairs = [];
            for (let i = 0; i < vars.length; i++) {
              const title = vars[i].split('=')[0];
              pairs.push({ [title]: vars[i].split('=')[1] });
            }
            return pairs;
          }

          return [];
        }
        const params = getQueryVariable();

        if (params.length > 1) {
          if (params[0].type !== '') {
            const types = params[0].type.split('%2C');

            this.currType = types.map((item) => {
              return decodeURIComponent(item);
            });
          }
          if (params[1].division !== '') {
            const divisions = params[1].division.split('%2C');

            this.currDivision = divisions.map((item) => {
              return decodeURIComponent(item);
            });
          }
          if (params[2].location !== '') {
            const locations = params[2].location.split('%2C');

            this.currLocation = locations.map((item) => {
              return decodeURIComponent(item);
            });
          }
          if (params[3].sortBy !== '') {
            this.sortBy = decodeURIComponent(params[3].sortBy);
          }
        }
        $.getJSON(dataUrl, (json) => {
          this.items = json;
          console.log(  this.items)
        });
      },

      mounted() {
        this.setParams();
        $(window).on('scroll', this.resolveResultBarPosition);

        this.setFirstLoadTriggerEvent();
      },

      template: `
        <div class="program-finder">
          <div class="program-finder__filter">
            <div class="program--list__sort">
              <label for="sort-by">Sort By</label>
              <div class="sort-by--wrapper">
                <select v-on:change="setParams()" v-model="sortBy" id="sort-by">
                  <option value="department">Department</option>
                  <option value="alphabetical">Alphabetical</option>
                </select>
              </div>
            </div>
            <button
              v-on:click="toggleFilters"
              class="program__filters__open"
              v-bind:aria-expanded="showFilters ? 'true' : 'false' " >
              <span class="filter-title">Filter</span>
              <span class="filter-icon"></span>
            </button>
            <div class="program__filters form">
              <div class="program--list__wrapper">
                <fieldset class="program__filters__fieldset">
                  <legend>Program Types</legend>
                  <div class="fields-wrapper">
                    <div class="program__filters__field form__radio">
                      <div class="form__checkbox-wrapper">
                        <input
                          type="radio"
                          name="program-type"
                          v-model="currType"
                          id="program-type-all"
                          v-on:change="setParams()"
                          value="program_type_all" />
                      </div>

                      <label for="program-type-all">All Program Types</label>
                    </div>
                    <div class="program__filters__field  form__radio" v-for="(item, index) in programType">
                      <div class="form__checkbox-wrapper">
                        <input
                          type="radio"
                          name="program-type"
                          v-on:change="setParams();clearAllBttn($event);"
                          v-model="currType"
                          v-bind:id="'program-type-'+index"
                          v-bind:value="item" />
                      </div>
   
                      <label v-bind:for="'program-type-'+index">{{ item }}</label>
                    </div>
                  </div>
                </fieldset>
                <fieldset class="program__filters__fieldset">
                  <legend>From</legend>
                  <div class="fields-wrapper">
                    <div class="program__filters__field form__checkbox">
                      <div class="form__checkbox-wrapper">
                        <input
                          type="checkbox"
                          name="program-division"
                          v-model="currDivision"
                          id="program-division-all"
                          v-on:change="setParams()"
                          value="program_division_all" />
                      </div>
       
                      <label for="program-division-all">All Colleges</label>
                    </div>
                    <div class="program__filters__field  form__checkbox" v-for="(item, index) in programDivision">
                      <div class="form__checkbox-wrapper">
                        <input
                          type="checkbox"
                          v-model="currDivision"
                          v-on:change="setParams();clearAllBttn($event);"
                          name="program-division"
                          v-bind:id="'program-division-'+index"
                          v-bind:value="item" />
                      </div>

                      <label v-bind:for="'program-division-'+index">{{ item }}</label>
                    </div>
                  </div>
                </fieldset>
                <fieldset class="program__filters__fieldset">
                  <legend>Located At</legend>
                  <div class="fields-wrapper">
                    <div class="program__filters__field form__checkbox">
                      <div class="form__checkbox-wrapper">
                        <input
                          type="checkbox"
                          name="program-location"
                          v-on:change="setParams()"
                          v-model="currLocation"
                          id="program-location-all"
                          value="program_location_all" />
                      </div>
                      <label for="program-location-all">Any Location or Online</label>
                    </div>

                    <div class="program__filters__field  form__checkbox" v-for="(item, index) in location">
                      <div class="form__checkbox-wrapper">
                        <input
                          type="checkbox"
                          v-model="currLocation"
                          name="program-location"
                          v-on:change="setParams();clearAllBttn($event);"
                          v-bind:id="'program-location-'+index"
                          v-bind:value="item" />
                      </div>
                      <label v-bind:for="'program-location-'+index">{{ item }}</label>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
          <div class="program--list" v-if="groupedResults.length">
            <div class="program--list__block" v-for="item in groupedResults">
              <h2>{{ item.type }}</h2>
              <ul class="program--list__items">
                <li class="program--list__item" v-for="(subitem, index) in item.data" :style=setStylesForListItem(index)>
                  <div>
                    <a v-bind:href="subitem.url">
                      <span class="program--list__title"> {{ subitem.title }}</span>
                      <span class="program--list__cred" v-if="subitem.credentialType"> ({{ subitem.credentialType.join(', ') }})</span>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      `,

      computed: {
        filteredResults() {
          let filteringTemp = this.items;

          // Filtering logic for each filter
          if (
            !this.currType.includes('program_type_all') &&
            this.currType.length
          ) {
            filteringTemp = filteringTemp.filter((item) => {
              if (item.programType) {
                return item.programType.some((currItem) =>
                  this.currType.includes(currItem),
                );
              }
             
            });
          }

          if (
            !this.currDivision.includes('program_division_all') &&
            this.currDivision.length
          ) {
            filteringTemp = filteringTemp.filter((item) => {
              if (item.programDivision) {
                return item.programDivision.some((currItem) =>
                  this.currDivision.includes(currItem),
                );
              }
            });
          }

          if (
            !this.currLocation.includes('program_location_all') &&
            this.currLocation.length
          ) {
            filteringTemp = filteringTemp.filter((item) => {
              if (item.location) {
                return item.location.some((currItem) =>
                  this.currLocation.includes(currItem),
                );
              }
            });
          }

          return filteringTemp;
        },

        // Get the items for each filter category
        programType() {
          return getFilterValues(this, 'programType');
        },

        programDivision() {
          return getFilterValues(this, 'programDivision');
        },

        location() {
          return getFilterValues(this, 'location');
        },

        // Sortby logic
        groupedResults() {
          if (this.sortBy === 'department') {
            // Create a new program array with each item inside a nested array, grouped by dept
            const temp = this.filteredResults.reduce((acc, obj) => {
              // Drill down to each department item for each program
              if (!obj.department) {
                obj.department = ['No department found'];
              }
              obj.department.forEach((item) => {
                /**
                 * Add program to the appropiate department array,
                 * Create new department array if there isn't one
                 */
                if (!acc[item]) {
                  acc[item] = [];
                }
                acc[item].push(obj);
              });
              return acc;
            }, {});
            return _(temp)
              .map((value, key) => {
                /**
                 * re-organize that array item so that it has the
                 * department and data as separate property/value pairs
                 * and sort it
                 */
                return {
                  type: key,
                  data: value,
                };
              })
              .sortBy([(item) => item.type.toLowerCase()])
              .value();
            // eslint-disable-next-line no-else-return
          } else {
            return _(this.filteredResults)
              .groupBy((item) => item.title.toUpperCase())
              .map((value, key) => {
                return {
                  type: key,
                  data: value,
                };
              })
              .value();
          }
        },
      },

      methods: {
        setFirstLoadTriggerEvent() {
          setTimeout(() => {
            $('.program-finder').addClass('js-active');
          }, 500)
        },

        setStylesForListItem(index) {
          const delayAmount = 0.150;
          const str = `transition-delay: ${delayAmount * index}s;`
          return str;
        },
        toggleFilters() {
          const EXPANDED_CLASSNAME = 'expanded';
          const $programFilters = $('.program__filters');

          if ($programFilters.hasClass(EXPANDED_CLASSNAME)) {
            $programFilters.removeClass(EXPANDED_CLASSNAME);

            setTimeout(() => {
              $programFilters.slideUp(300);
            }, 300);
          } else {
            $programFilters.slideDown(300, () => {
              $programFilters.addClass(EXPANDED_CLASSNAME);
            });
          }

          this.showFilters = !this.showFilters;
        },
        setParams() {
          const type = this.currType;
          const division = this.currDivision;
          const location = this.currLocation;
          const { sortBy } = this;

          window.history.replaceState(
            {},
            document.title,
            `?type=${encodeURIComponent(type)}&division=${encodeURIComponent(
              division,
            )}&location=${encodeURIComponent(location)}&sortBy=${sortBy}`,
          );
        },
        clearAllBttn(e) {
          const bttnAll = $(e.target)
            .closest('.fields-wrapper')
            .find('.program__filters__field:first-of-type input');

          if ($(e.target).is(':checked')) {
            if (bttnAll[0].checked) {
              bttnAll[0].click();
            }
          }
        },
        resetFilters() {
          this.currType = [];
          this.currDivision = [];
          this.currLocation = [];
          this.setParams();
        },
      },
    }).mount('#program-list');
  },
};
