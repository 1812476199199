/* global YT */

const tag = document.createElement('script');

tag.src = 'https://www.youtube.com/iframe_api';
const firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

const heroVideo = (() => {
  const init = () => {
    const ANIMATION_SPEED = 300;
    const DISABLE_SCROLL_CLASS_NAME = 'js-fixed';
    const $body = $('body');
    const $heroSection = $('.hero--has-video');
    const $videoModal = $heroSection.find('.hero__video-modal');
    const $videoModalOpener = $heroSection.find('.hero__open-video-modal');
    const $videoModalClose = $heroSection.find('.hero__close-video-modal');

    if (!$videoModal) {
      return
    }

    let player;

    function vidInline(e) {
      e.preventDefault();

      // Attach YouTube API
      const video = $videoModal.find('iframe')[0];

      function onYouTubeIframeAPIReady() {
        setTimeout(() => {
          player = new YT.Player(video, {
            events: {
              // eslint-disable-next-line no-use-before-define
              onReady: onPlayerReady,
              // eslint-disable-next-line no-use-before-define
              onStateChange: onPlayerStateChange,
            },
          });
        }, 100);
      }

      onYouTubeIframeAPIReady();

      function onPlayerReady() {
        player.playVideo();
      }

      function onPlayerStateChange(event) {

        if (event.data === YT.PlayerState.UNSTARTED) {
          $videoModal.addClass('js-loading');
        }

        if (event.data === YT.PlayerState.PLAYING) {
          $videoModal.find('iframe').removeAttr('tabindex');
        }
      }
    }

    $videoModalOpener.on('click', (e) => {
      $body.addClass(DISABLE_SCROLL_CLASS_NAME);
      $videoModal.fadeIn(ANIMATION_SPEED).attr('aria-hidden', false);
      $($videoModalOpener, $videoModalClose).attr('aria-expanded', true);
      $('.modal-content').focus();

      if (player == undefined) {
        vidInline(e);
      }
      else {
        player.playVideo();
      }
    });

    $videoModalClose.on('click', () => {
      $body.removeClass(DISABLE_SCROLL_CLASS_NAME);
      $videoModal.fadeOut(ANIMATION_SPEED).attr('aria-hidden', true);
      $($videoModalOpener, $videoModalClose).attr('aria-expanded', false);
      $('.hero__open-video-modal').focus();
      player.stopVideo();
    });

    const tabOne = $('.modal-content');
    const tabTwo = $('.hero__close-video-modal');

    tabOne.on('keydown', e => {
      if (e.key === 'Tab' && e.shiftKey) {
        e.preventDefault();
        tabTwo.focus();
      }
    });

    tabTwo.on('keydown', e => {
      if (e.key === 'Tab' && !e.shiftKey) {
        e.preventDefault();
        tabOne.focus();
      }
    });
  }

  return {
    init,
  };
})();

export default heroVideo;
