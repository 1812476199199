/**
 * Carousel display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if instagram is present
 */

const instagram = (() => {
  // Render carousel
  function getFeed(token) {
    const count = 4;
    const ids = [];

    $.ajax({
      method: "GET",
      url: `https://graph.instagram.com/me/media?fields=id,caption&access_token=${token}`,
    }).done((PostIds) => {
      for (let i = 0; i < count; i++) {
        ids.push(PostIds.data[i].id);
      }
      const runRequests = (index) => {
        if (ids.length === index) {
          return;
        }
        const id = ids[index];
        $.ajax({
          url: `https://graph.instagram.com/${id}?fields=caption,media_type,media_url,permalink,thumbnail_url&access_token=${token}`,
          success: (data) => {
            let mediaUrl = "";
            if (data.media_type === "VIDEO") {
              mediaUrl = data.thumbnail_url;
            } else {
              mediaUrl = data.media_url;
            }
            /**
              Edit the html of each column/post here
              available variables:
                mediaUrl: the URL of the image (thumbnail image for video post types)
                data.permalink: Link to the post on insta
                data.caption: Post caption
                data.media_type: Type of Media
                  returns 'IMAGE' or 'VIDEO'
            */

            if (data.caption === undefined) {
              data.caption = "Instagram image with no caption";
            }

            const html = `
              <div class="instagram__tile">
                <a href="${data.permalink}" target="_blank" class="instagram__link" style="background-image: url(${mediaUrl})">
                  ${data.caption}
                </a>
              </div>
              `;

            $(".instagram__feed").append(html);
          },
          complete() {
            runRequests((index += 1));
          },
        });
      };
      runRequests(0);
    });
  }

  function renderInstagram() {
    // Get API token from instant-token
    const $URL = $(".instagram").attr("data-url");
    let dataJSON = "";
    $.ajax({
      method: "GET",
      url: $URL,
    }).done((data) => {
      dataJSON = JSON.parse(data);

      getFeed(dataJSON.Token);
    });
  }

  const init = () => {
    if (!$(".instagram[data-url]").length) {
      return;
    }

    renderInstagram();
  };

  return {
    init,
  };
})();

export default instagram;
