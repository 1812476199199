/**
 * Carousel display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if carousel present
 */

const carousel = (() => {
  const nextBtnTemplate = `<button class="slick-next slick-arrow slick-arrow--custom"type="button"><span class="slick-sr-only">Next</span><span class="slick-arrow__icon" aria-hidden="true"></span></button>`;
  const prevBtnTemplate = `<button class="slick-prev slick-arrow slick-disabled slick-arrow--custom" disabled type="button"><span class="slick-sr-only">Previous</span><span class="slick-arrow__icon" aria-hidden="true"></span></button>`;


  const customSlickPrev = (el) => {
    $(el).closest('.carousel').addClass('js-fadeout-before');

    setTimeout(() => {
      $(el).closest('.carousel').find('.carousel__wrapper--caption').slick('slickPrev');
      $(el).closest('.carousel').removeClass('js-fadeout-before');
    }, 600);
  };

  const customSlickNext = (el) => {
    $(el).closest('.carousel').addClass('js-fadeout-before');

    setTimeout(() => {
      $(el).closest('.carousel').find('.carousel__wrapper--caption').slick('slickNext');
      $(el).closest('.carousel').removeClass('js-fadeout-before');
    }, 100);
  };

  const addCustomControls = (elem) => {
    const $btnNext = $(nextBtnTemplate);
    const $btnPrev = $(prevBtnTemplate);

    $btnNext.on('click touchstart', (e) => {
      customSlickNext(e.target);
    });

    $btnPrev.on('click touchstart', (e) => {
      customSlickPrev(e.target);
    });

    elem.append($btnNext);
    elem.prepend($btnPrev);
  };

  // Render carousel
  function renderCarousel() {
    const $elem = $('.carousel');

    $elem.each((i, el) => {
      const count = $(el).find('.carousel__caption').length;

      $(el).find('.carousel-counter__total').html(count);

      $(el).find('.carousel__wrapper--img').slick({
        dots: false,
        infinite: false,
        arrows: false,
        speed: 0,
        slidesToShow: 1,
        asNavFor: '.carousel__wrapper--caption'
      });

      $(el).find('.carousel__wrapper--caption').slick({
        dots: false,
        infinite: false,
        fade: true,
        speed: 0,
        slidesToShow: 1,
        focusOnSelect: true,
        adaptiveHeight: true,
        arrows: false,
        asNavFor: '.carousel__wrapper--img',
        appendArrows: $elem.eq(i).find('.carousel__controller')
      });

      $(el).find('.carousel__wrapper--img, .carousel__wrapper--caption').removeAttr('role');
      $(el).find('.carousel__wrapper--img, .carousel__wrapper--caption').removeAttr('aria-label');

      addCustomControls($elem.eq(i).find('.carousel__controller'));

      $(el).find('.carousel__wrapper--caption').on('afterChange', function (event, slick, currentSlide, nextSlide) {
        $(el).find('.carousel-counter__current').html(currentSlide + 1);

        if (currentSlide == 0) {
          $elem.find('.slick-prev').addClass('slick-disabled');
        }
        else {
          $elem.find('.slick-prev').removeClass('slick-disabled');
        }

        if (currentSlide == count - 1) {
          $elem.find('.slick-next').addClass('slick-disabled');
        }
        else {
          $elem.find('.slick-next').removeClass('slick-disabled');
        }

        // Add disabled attribute to controller
        $('.carousel__controller .slick-arrow').attr('disabled', false);

        setTimeout(() => {
          $('.carousel__controller .slick-arrow').each((index, el) => {
            if ($(el).hasClass('slick-disabled')) {
              $(el).attr('disabled', true);
            }
          });
        }, 600);
      });
    });
  }

  const init = () => {
    if (!$('.carousel').length) {
      return;
    }

    renderCarousel();
  };

  return {
    init,
  };
})();

export default carousel;
