/**
 * Student Life 3 Column display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if Student Life 3 Column is present
 */

const studentLife3Col = (() => {
  function render() {
    const resetColumnPosition = ($column) => {
      $column.removeAttr('style');
    }

    const setColumnPosition = ($window, $parallaxColumnsBox, $column) => {
      const heightDelta = $column.outerHeight() - $parallaxColumnsBox.outerHeight() - ($(window).height() / 2);
      let scrollDelta = ($window.scrollTop() + ($window.height() * 0.1)) - $parallaxColumnsBox.offset().top;
      scrollDelta = scrollDelta < 20 ? 0 : scrollDelta < $parallaxColumnsBox.outerHeight() ? scrollDelta : $parallaxColumnsBox.outerHeight();
      scrollDelta = scrollDelta / $parallaxColumnsBox.outerHeight();
      $column.css('transform', `translateY(${(heightDelta * (scrollDelta * 0.3))}px) scale(1.4)`);
    }

    $('.students-life-3col-list').each((index, elem) => {
      const $parallaxColumnsBox = $(elem);
      const $column = $(elem).find('.students-life-3col-list__intro-img > img');


      setColumnPosition($(window), $parallaxColumnsBox, $column);

      $(window).on('scroll', () => {
        if ($(window).width() >= 700) {
          setColumnPosition($(window), $parallaxColumnsBox, $column);
        }
        else {
          resetColumnPosition($column);
        }
      });
    });
  }

  const init = () => {
    if (!$('.students-life-3col-list').length) {
      return;
    }

    render();
  };

  return {
    init,
  };
})();
 
export default studentLife3Col;