const sectionNav = (() => {

  
  const init = () => {
    const ANIMATION_SPEED = 300;
    const $window = $(window);
    const NAV_OPENED_CLASSNAME = 'section-nav-opened';
    const $sectionNavOpen = $('.section-nav__opener');
    const $sectionNavClose = $('.section-nav__close');
    const $sectionNavToggle = $('.section-nav__opener, .section-nav__close');
    const $sectionNavBox = $('.section-nav__box');
    const $lastSectionLink = $('.section-nav__item-link').last();
    const $parentLink = $('.section-nav__parent a');
    const $body = $('body');

    $sectionNavToggle.on('click', () => {
      if(!$body.hasClass(NAV_OPENED_CLASSNAME)) {
        $sectionNavToggle.attr('aria-expanded', true);
        $body.addClass(NAV_OPENED_CLASSNAME);
        $sectionNavBox.slideDown(ANIMATION_SPEED, () => {
          $parentLink.focus();
        }).attr('aria-hidden', false);
        if($window.width() < 700) {
          $body.addClass('js-fixed');
        }
      } else {
        $sectionNavToggle.attr('aria-expanded', false);
        $body.removeClass(`${NAV_OPENED_CLASSNAME} js-fixed`);
        $sectionNavBox.slideUp(ANIMATION_SPEED).attr('aria-hidden', true);
      }
    });

    $sectionNavOpen.on('keydown', (e) => {
      if (e.keyCode === 9 && !e.shiftKey && $body.hasClass(NAV_OPENED_CLASSNAME)) {
        e.preventDefault();
        $parentLink.focus();
      }

      if (e.keyCode === 9 && e.shiftKey && $body.hasClass(NAV_OPENED_CLASSNAME)) {
        e.preventDefault();
        $lastSectionLink.focus();
      }
    });

    $lastSectionLink.on('keydown', (e) => {
      if (e.keyCode === 9 && !e.shiftKey && $body.hasClass(NAV_OPENED_CLASSNAME)) {
        e.preventDefault();
        if ($window.width() < 700) {
          $sectionNavClose.focus();
        } else {
          $sectionNavOpen.focus();
        }
      }
    });

    $parentLink.on('keydown', (e) => {
      if (e.keyCode === 9 && e.shiftKey && $body.hasClass(NAV_OPENED_CLASSNAME)) {
        e.preventDefault();

        if ($window.width() < 700) {
          $sectionNavClose.focus();
        } else {
          $sectionNavOpen.focus();
        }
      }
    });

    $sectionNavClose.on('keydown', (e) => {
      if (e.keyCode === 9 && e.shiftKey && $body.hasClass(NAV_OPENED_CLASSNAME)) {
        e.preventDefault();
        $lastSectionLink.focus();
      }
    });

    
  }

  return {
    init,
  }
})();

export default sectionNav;