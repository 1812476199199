const featuredCarousel = (() => {
  const setScrollHeight = (elem) => {
    let offsetMargin = 80;

    if ($(window).width() >= 1025) {
      offsetMargin = 160;
    }

    const $scrollableWidth = $(elem).find('.featured-carousel__cards-holder')[0].scrollWidth - offsetMargin;

    if ($(elem).find('.featured-carousel__wrapper').height() < $(window).height()) {
      $(elem).addClass('js-is-horizontally-scrollable');
      $(elem).css('height', $scrollableWidth);
    }
    else {
      $(elem).removeClass('js-is-horizontally-scrollable');
      $(elem).removeAttr('style');
    }
  }

  const setHorizontalScrollPosition = (elem) => {
    if ($(elem).hasClass('js-is-horizontally-scrollable')) {
      const $galleryContainer = $(elem).find('.featured-carousel__cards-holder');
      const SCROLL_VAL = -1 * elem.getBoundingClientRect().top;

      $galleryContainer[0].scrollLeft = SCROLL_VAL;
    }
  }

  const setArrowStates = (elem) => {
    let offsetMargin = 80;

    if ($(window).width() >= 1025) {
      offsetMargin = 160;
    }

    const $galleryContainer = $(elem).find('.featured-carousel__cards-holder');
    const $bttnPrev = $(elem).find('.featured-carousel__button--prev');
    const $bttnNext = $(elem).find('.featured-carousel__button--next');
    const maxScroll = $galleryContainer[0].scrollWidth - $galleryContainer[0].clientWidth - 50;

    if ($galleryContainer[0].scrollLeft <= 50) {
      $bttnPrev.attr('disabled', true);
    }
    else {
      $bttnPrev.attr('disabled', false);
    }

    if ($galleryContainer[0].scrollLeft >= maxScroll) {
      $bttnNext.attr('disabled', true);
    }
    else {
      $bttnNext.attr('disabled', false);
    }
  }

  const init = () => {
    if (!$('.featured-carousel__section').length) {
      return;
    }

    const $carousel = $('.featured-carousel__section');

    $carousel.each((i, elem) => {
      const $btnNext = $(elem).find('.featured-carousel__button--next');
      const $btnPrev = $(elem).find('.featured-carousel__button--prev');
      const $galleryContainer = $(elem).find('.featured-carousel__cards-holder');

      $(window).on('load scroll resize', () => {
        setScrollHeight(elem);
      });

      $(window).on('scroll', () => {
        setHorizontalScrollPosition(elem);
      });

      $galleryContainer.on('scroll', () => {
        setArrowStates(elem);
      });

      $btnNext.on('click', () => {
        const SCROLL_VAL = document.documentElement.scrollTop + $(window).width();

        if ($(elem).hasClass('js-is-horizontally-scrollable')) {
          $('html, body').animate(
            {
              scrollTop: SCROLL_VAL,
            },
            600,
          );
        }
        else {
          $galleryContainer.animate(
            {
              scrollLeft: SCROLL_VAL,
            },
            600,
          );
        }
      });

      $btnPrev.on('click', () => {

        const SCROLL_VAL = -1 * (document.documentElement.scrollTop + $(window).width());

        if ($(elem).hasClass('js-is-horizontally-scrollable')) {
          $('html, body').animate(
            {
              scrollTop: $(elem)[0].offsetTop,
            },
            600,
          );
        }
        else {
          $galleryContainer.animate(
            {
              scrollLeft: SCROLL_VAL,
            },
            600,
          );
        }
      });
    });
  };

  return {
    init,
  };
})();

export default featuredCarousel;