const bottomArrow = (() => {
  const addBottomArrowSpan = (selectors, timeout) => {
    setTimeout(() => {
      $(selectors).each((i, elem) => {
        // eslint-disable-next-line max-nested-callbacks
        $(elem).addClass('has-bottom-arrow').append('<div class="bottom-arrow"><span></span></div>')
      });
    }, timeout);
  };

  const init = () => {
    const selectors = `.news-and-events .news__card, .parallax-gallery__grid-item--has-anchor, .featured-2-columns__card, .events3up__item, .related-programs__card, .featured-3-columns__card`;

    $(document).ready(function () {
      // adds last word spans to any of the 'selectors' elements site-wide
      addBottomArrowSpan(selectors, 200);
    });

    $('.toggle-gallery__card-title').each(function() {
      $(this).after('<div class="bottom-arrow"><span></span></div>')
    })
  };

  return {
    init,
    addBottomArrowSpan,
  };
})();

export default bottomArrow;
