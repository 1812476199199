/**
 * Header display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if Header is present
 */

const header = (() => {
  const HEADER_HIDDEN_CLASS_NAME = 'js-scroll-hidden';
  const HEADER_ON_TOP_CLASS_NAME = 'js-is-on-top';

  const headerPosition = () => {
    const $mainHeader = $('.header');
    let lastScrollPos = 0;
    let currentScrollPos = 0;
    const $alertHeight = $('.alert').outerHeight() || 0;

    $(window).on('scroll load', () => {
      currentScrollPos = $(window).scrollTop();
      
      // if ((lastScrollPos < currentScrollPos) && ($(window).scrollTop() > ($('.header__inner').outerHeight()) + $alertHeight)) {
      //   $mainHeader.addClass(HEADER_HIDDEN_CLASS_NAME);
      // } else {
      //   $mainHeader.removeClass(HEADER_HIDDEN_CLASS_NAME);
      // }

      if (currentScrollPos === 0) {
        $mainHeader.addClass(HEADER_ON_TOP_CLASS_NAME);
      } else {
        $mainHeader.removeClass(HEADER_ON_TOP_CLASS_NAME);
      }

      lastScrollPos = currentScrollPos;
    });
  };

  function render() {
    $(".header-main__button").on("click", (e) => {
      const dropdown = $(e.target.parentElement).next();

      if (e.target.getAttribute("aria-expanded") === "false") {
        e.target.setAttribute("aria-expanded", "true");
        dropdown.slideDown(150);
        $(".header").addClass("js-header-active");
        $("body, html").addClass("js-fixed");
      } else {
        e.target.setAttribute("aria-expanded", "false");
        dropdown.slideUp(150);
        $("body, html").removeClass("js-fixed");
        $(".header").removeClass("js-header-active");
      }
    });

    $(".nav-primary__submenu-toggle").on("click", (e) => {
      const dropdown = $(e.target).next();

      if (e.target.getAttribute("aria-expanded") === "false") {
        e.target.setAttribute("aria-expanded", "true");
        dropdown.attr("aria-hidden", "false");
        dropdown.slideDown(150);
      } else {
        e.target.setAttribute("aria-expanded", "false");
        dropdown.attr("aria-hidden", "true");
        dropdown.slideUp(150);
      }
    });

    $(".i-am-a__bttn").on("click", (e) => {
      const dropdown = $(e.target).next();

      if (e.target.getAttribute("aria-expanded") === "false") {
        e.target.setAttribute("aria-expanded", "true");
        dropdown.attr("aria-hidden", "false");
        dropdown.slideDown(150);
      } else {
        e.target.setAttribute("aria-expanded", "false");
        dropdown.attr("aria-hidden", "true");
        dropdown.slideUp(150);
      }
    });

    $(".header-search__button").on("click", (e) => {
      const dropdown = $(e.target).next();

      if (e.target.getAttribute("aria-expanded") === "false") {
        e.target.setAttribute("aria-expanded", "true");
        $(".header").addClass("js-search-active");
        $("#nav-search").focus();
      } else {
        e.target.setAttribute("aria-expanded", "false");
        $(".header").removeClass("js-search-active");
      }
    });

    $(".nav-primary-item--with-children > .nav-primary-item-link").on("mouseenter", (e) => {
      if ($(window).width() >= 1025) {
        e.preventDefault();
        e.stopPropagation();

        $(e.target)
          .next()
          .next()
          .slideDown(150);
      }
    });

    $(".nav-primary-item--with-children").on("mouseenter", (e) => {
      if ($(window).width() >= 1025) {
        e.preventDefault();
        e.stopPropagation();

        $(e.target)
          .closest(".nav-primary-item--with-children")
          .addClass("js-open");
        $(e.target)
          .parent(".nav-primary-item--with-children")
          .find(".nav-primary__submenu-toggle")
          .attr("aria-expanded", "true");
        $(e.target)
          .find(".nav-primary__submenu")
          .attr("aria-hidden", "false")
          .slideDown(150);

        $(document).on("keydown", (event) => {
          if (
            event.which === 27 &&
            document.querySelectorAll(
              ".nav-primary-item--with-children.js-open"
            ).length
          ) {
            const openedMenuItem = document.querySelector(
              ".nav-primary-item--with-children.js-open"
            );
            const openedMenuItemToggle = openedMenuItem.querySelector(
              ".nav-primary__submenu-toggle"
            );
            const openedMenuItemSubmenu = openedMenuItem.querySelector(
              ".nav-primary__submenu"
            );
            event.preventDefault();
            event.stopPropagation();

            $(openedMenuItem).removeClass("js-open");
            $(openedMenuItemToggle).attr("aria-expanded", "false");
            $(openedMenuItemSubmenu).attr("aria-hidden", "true").slideUp(150);
          }
        });
      }
    });

    $(".nav-primary-item--with-children").on("mouseleave", (e) => {
      if ($(window).width() >= 1025) {
        e.preventDefault();
        e.stopPropagation();

        $(e.target)
          .closest(".nav-primary-item--with-children")
          .removeClass("js-open");
        $(e.target)
          .parent(".nav-primary-item--with-children")
          .find(".nav-primary__submenu-toggle")
          .attr("aria-expanded", "false");
        $(e.target)
          .closest(".nav-primary-item--with-children")
          .find(".nav-primary__submenu")
          .attr("aria-hidden", "true")
          .slideUp(150);
      }
    });

    // Search tab loop
    const searchTabOne = $(".header-search__button");
    const searchTabTwo = $('.search-form > button[type="submit"]');

    searchTabOne.on("keydown", (e) => {
      if (e.key === "Tab" && e.shiftKey) {
        if ($(e.target).attr("aria-expanded") === "true") {
          e.preventDefault();
          searchTabTwo.trigger("focus");
        }
      }
    });

    searchTabTwo.on("keydown", (e) => {
      if (e.key === "Tab" && !e.shiftKey) {
        e.preventDefault();
        searchTabOne.trigger("focus");
      }
    });

    // Main nav tab loop
    const mainNavTabOne = $(".header-main__button");
    const mainNavTabTwo = $(".header-ancillary-item li:last > a");

    mainNavTabOne.on("keydown", (e) => {
      if (e.key === "Tab" && e.shiftKey) {
        if ($(e.target).attr("aria-expanded") === "true") {
          e.preventDefault();
          mainNavTabTwo.trigger("focus");
        }
      }
    });

    mainNavTabTwo.on("keydown", (e) => {
      if (e.key === "Tab" && !e.shiftKey) {
        e.preventDefault();
        mainNavTabOne.trigger("focus");
      }
    });
  }

  const headerFocusLoop = () => {
    const tabOne = $('.header-main__button');
    const tabTwo = $('.header-ancillary-item:last > a');

    tabOne.on('keydown', e => {
      if ($(window).width() < 1025 && e.key === 'Tab' && e.shiftKey) {
        if ($(e.target).attr('aria-expanded') === 'true') {
          e.preventDefault();
          tabTwo.focus();
        }
      }
    });

    tabTwo.on('keydown', e => {
      if ($(window).width() < 1025 && e.key === 'Tab' && !e.shiftKey) {
        e.preventDefault();
        tabOne.focus();
      }
    });
  }

  const largeScreenCheckSetHeightInterval = () => {
    setInterval(myCallback, 300);
  }

  function myCallback() {
    const $LinkContainer = $('.header-main__dropdown.dropdown');
    const $hWrapper = $('.header__wrapper')

    if ($(window).width() >= 1025) {
      $hWrapper.css({'padding-bottom': $LinkContainer.height()})
    }
    else {
      $hWrapper.removeAttr('style');
    }
  }

  const init = () => {
    if (!$(".header").length) {
      return;
    }

    render();
    headerPosition();
    headerFocusLoop();
    largeScreenCheckSetHeightInterval();
  };

  return {
    init,
  };
})();

export default header;
