const backToTopButton = (() => {
  const init = () => {
    const $firstFocusable = $('button, a, input, [tab-index]').first();
    const $window = $(window);
    const $footerSectionsHolder = $('.footer-sections-holder');
    const $backToTopButton = $footerSectionsHolder.find('.back-to-top');
    let $buttonTopShift;

    const defineButtonTopShift = () => {
      if ($window.width() < 700) {
        $buttonTopShift = 40 - 16; // button height - button top position
        return
      }

      if ($window.width() >= 700 && $window.width() <= 1025) {
        $buttonTopShift = 50 - 22; // button height - button top position
        return
      }

      if ($window.width() >= 1025) {
        $buttonTopShift = 60 - 26; // button height - button top position
        return
      }
    }

    defineButtonTopShift();

    $window.on('resize', () => {
      defineButtonTopShift();
    });

    $window.on('scroll', () => {
      if (($window.scrollTop() + $window.height()) >= ($footerSectionsHolder.offset().top + $buttonTopShift)) {
        $('body').addClass('back-to-top-static');
      } else {
        $('body').removeClass('back-to-top-static');
      }
    });

    $backToTopButton.on('click', () => {
      $('html, body').animate({scrollTop: 0}, 300, () => {
        $firstFocusable.focus();
      });
    });
  }

  return {
    init,
  }
})();

export default backToTopButton;