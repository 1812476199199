const homeHeroVideo = (() => {
  const init = () => {
    const IS_PAUSED_CLASS_NAME = 'is-paused';
    const CAN_PLAY_CLASS_NAME = 'can-play';
    const $heroSection = $('.hero--home');
    const videoPlayer = $heroSection.find('.hero__video')[0];
    const $playButton = $heroSection.find('.play-video-btn');

    if (!videoPlayer) {
      return
    }

    videoPlayer.oncanplay = () => {
      $heroSection.addClass(CAN_PLAY_CLASS_NAME);
    }

    if (videoPlayer.readyState >= videoPlayer.HAVE_FUTURE_DATA) {
      $heroSection.addClass(CAN_PLAY_CLASS_NAME);
    }

    $playButton.on('click', () => {
      if ($heroSection.hasClass(IS_PAUSED_CLASS_NAME)) {
        videoPlayer.play();
        $heroSection.removeClass(IS_PAUSED_CLASS_NAME);
        $playButton.find('.visually-hidden').text('pause video');
      } else {
        videoPlayer.pause();
        $heroSection.addClass(IS_PAUSED_CLASS_NAME);
        $playButton.find('.visually-hidden').text('play video');
      }
    });
  }

  return {
    init,
  };
})();

export default homeHeroVideo;
