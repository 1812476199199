const verticalCarousel = (() => {
  const setLocBarPos = ($index) => {
    let topPadding = 19;

    if ($(window).width() >= 1025) {
      topPadding = 39;
    }
    else {
      topPadding = 19;
    }

    const activeElem = $('.vertical-carousel__bullets-item').eq($index);
    const activeLinkPos = (activeElem.offset().top - $('.vertical-carousel__bullets-list').offset().top) + topPadding;
    $('.loc-bar').attr('data-location', $index);
    $('.loc-bar').css({ 'top': `${activeLinkPos}px`, 'height': `${activeElem.height()}px` });
  }

  const init = () => {
    if (!$('.vertical-carousel').length) {
      return;
    }

    $('.vertical-carousel__bullets-item button').on('click', (e) => {
      const $parent = $(e.target).parent();
      const $index = $('.vertical-carousel__bullets-item').index($parent);

      const $carouselItemToScrollTo = $('.vertical-carousel .vertical-carousel__item').eq($index);

      $('html, body').animate(
        {
          scrollTop: $carouselItemToScrollTo.offset().top
        },
        300, "linear", function () {
          $carouselItemToScrollTo.find('.vertical-carousel__title > a').focus();
        });
    });

    setLocBarPos(0);

    $(window).on('scroll', (e) => {
      const $scrollPosCentered = $(window).scrollTop();
      let $indexOfClosest = 0;
      let $prevScrollPos = 999999;

      $('.vertical-carousel__item').each((i, elem) => {
        const $itemScrollPosCentered = ($('.vertical-carousel .vertical-carousel__item').eq(i).offset().top);

        // console.log(Math.abs($scrollPosCentered - $itemScrollPosCentered));

        if (Math.abs($scrollPosCentered - $itemScrollPosCentered) < $prevScrollPos) {
          $indexOfClosest = i;
          $prevScrollPos = Math.abs($scrollPosCentered - $itemScrollPosCentered);
        }

        if ($scrollPosCentered <= $('.vertical-carousel').height() / 1.5) {
          $indexOfClosest = 0;
        }

        setLocBarPos($indexOfClosest);
      });
    });


  };

  return {
    init,
  };
})();

export default verticalCarousel;
