const lastWord = (() => {
  const addLastWordSpan = (selectors, timeout) => {
    setTimeout(() => {
      $(selectors).each((i, elem) => {
        // eslint-disable-next-line max-nested-callbacks
        $(elem).html(function () {
          // separate the text by spaces
          const $this = $.trim($(this).text());
          const text = $this.split(" ");
          // drop the last word and store it in a variable
          const last = text.pop();
          // join the text back and if it has more than 1 word add the span tag
          // to the last word
          return `${text.join(
            " "
          )} <span class="last-word">${last}<span></span></span>`;
        });
      });
    }, timeout);
  };

  const init = () => {
    const selectors = `.form--actions > button[type="submit"], .stat3up__cta a, .cta__inner, .column__title a, .section-nav__item-link, .nav-primary-item-link, .vertical-carousel__title a, .contact-callout__link, .link-list__top .cta__inner, .link-list__content .cta--link, .news__title a`;

    $(document).ready(function () {
      // adds last word spans to any of the 'selectors' elements site-wide
      addLastWordSpan(selectors, 200);
    });
  };

  return {
    init,
    addLastWordSpan,
  };
})();

export default lastWord;
