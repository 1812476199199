/**
 * @param {*} $
 * @returns {init} Determine and run if scroll trigger elements present
 */

const stickyCta = (() => {
  const CTA_VISIBLE_CLASSNAME = 'cta-list--visible';
  const CLOSED_MANUALLY_CLASSNAME = 'cta-list-closed-manually';

  const closeCta = ($body, $ctaToggle, $ctaList) => {
    $body.removeClass(CTA_VISIBLE_CLASSNAME);
    $ctaToggle.attr('aria-expanded', false);
    $ctaList.attr('aria-hidden', true);
    $ctaToggle.find('.sticky-cta__toggle-text').text('Expand actions');
  };

  const openCta = ($body, $ctaToggle, $ctaList) => {
    $body.addClass(CTA_VISIBLE_CLASSNAME);
    $ctaToggle.attr('aria-expanded', true);
    $ctaList.attr('aria-hidden', false);
    $ctaToggle.find('.sticky-cta__toggle-text').text('Collapse actions');
  };

  const homepageScroll = ($window, $hero, $ctaToggle, $ctaList, $body) => {
    if ($window.scrollTop() > $hero.outerHeight() && $window.scrollTop() + $window.height() < $('.footer-sections-holder').offset().top + ($window.height() * 0.7)) {
      $('.sticky-cta').removeClass('sticky-cta--hidden');
      $('.hero--home .cta__list').addClass('js-active');

      if (!$body.hasClass(CLOSED_MANUALLY_CLASSNAME)) {
        openCta($body, $ctaToggle, $ctaList);
      }
    }
    else {
      $('.sticky-cta').addClass('sticky-cta--hidden');
      $('.hero--home .cta__list').removeClass('js-active');
    }
  }

  const otherPagesScroll = ($window, $hero, $ctaToggle, $ctaList, $body) => {
    if ($window.scrollTop() + $window.height() < $('.footer-sections-holder').offset().top + ($window.height() * 0.7)) {
      $('.sticky-cta').removeClass('sticky-cta--hidden');

      if (!$body.hasClass(CLOSED_MANUALLY_CLASSNAME)) {
        openCta($body, $ctaToggle, $ctaList);
      }
    }
    else {
      $('.sticky-cta').addClass('sticky-cta--hidden');
    }
  }

  const init = () => {
    const $window = $(window);
    const $body = $('body');
    const $ctaToggle = $('.sticky-cta__toggle');
    const $ctaList = $('.sticky-cta .cta__list');
    const visitedBefore = Boolean(localStorage.getItem("visitedBefore"));
    const $hero = $('.hero');
    const isOnHome = $hero.hasClass('hero--home');

    if (isOnHome) {
     homepageScroll($window, $hero, $ctaToggle, $ctaList, $body);
    } else {
      otherPagesScroll($window, $hero, $ctaToggle, $ctaList, $body);

      if (!visitedBefore) {
        if ($window.scrollTop() < $hero.outerHeight()) {
          openCta($body, $ctaToggle, $ctaList);
        }
      }
    }

    $ctaToggle.on('click', () => {
      if ($body.hasClass(CTA_VISIBLE_CLASSNAME)) {
        closeCta($body, $ctaToggle, $ctaList);
        $body.addClass(CLOSED_MANUALLY_CLASSNAME);
      } else {
        openCta($body, $ctaToggle, $ctaList, $body);
      }
    });

    $ctaToggle.on('focusin', (e) => {
      $('.sticky-cta').addClass('js-header-visible');
      $('.sticky-cta').removeClass('sticky-cta--hidden');
      openCta($body, $ctaToggle, $ctaList, $body);
    });

    $window.on('scroll', () => {
      if (isOnHome) {
        homepageScroll($window, $hero, $ctaToggle, $ctaList, $body);
      }
      else {
        otherPagesScroll($window, $hero, $ctaToggle, $ctaList, $body);
      }

      if (!visitedBefore) {
        if ($window.scrollTop() > $hero.outerHeight()) {
          closeCta($body, $ctaToggle, $ctaList);
        } else {
          openCta($body, $ctaToggle, $ctaList);
        }
      }

      if (!$('.header').hasClass('js-scroll-hidden')) {
        $('.sticky-cta').addClass('js-header-visible');
      }
      else {
        $('.sticky-cta').removeClass('js-header-visible');
      }
    });

    if (!visitedBefore) {
      localStorage.setItem("visitedBefore", "true");
    }
  };

  return {
    init,
  };
})();

export default stickyCta;
