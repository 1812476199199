const quoteTabs = (() => {
  const ACTIVE_CLASS_NAME = 'active';
  const ANIMATION_SPEED = 300;

  const resizeContentBox = () => {
    if ($(window).width() >= 1025) {
      const boxHeight = $('.quote-tabs__item-box:visible').outerHeight();
      $('.quote-tabs__box').css({ 'height': boxHeight });
    }
    else {
      $('.quote-tabs__box').removeAttr('style');
    }

    // additionally, reset quotebar to position
    setQuoteBarPos($('.quote-bar').attr('data-location'));
  }

  const setQuoteBarPos = ($index) => {
    let topPadding = 0;

    const activeElem = $('.quote-tabs__nav-button').eq($index);
    const activeLinkPos = (activeElem.offset().top - $('.quote-tabs__nav-list').offset().top) + topPadding;
    $('.quote-bar').attr('data-location', $index);
    $('.quote-bar').css({ 'top': `${activeLinkPos}px`, 'height': `${activeElem.innerHeight()}px` });
  }

  const init = () => {
    const $quoteTabsSections = $('.quote-tabs');

    $quoteTabsSections.each((index, quoteTabsSection) => {
      const $quoteTabsSection = $(quoteTabsSection);
      const $quoteTabsToggle = $quoteTabsSection.find('.quote-tabs__nav-button');
      const $quoteTabsMobileToggle = $quoteTabsSection.find('.quote-tabs__item-opener');
      const $panels = $quoteTabsSection.find('[data-quote-panel]');

      $quoteTabsMobileToggle.each((index, opener) => {
        const $opener = $(opener);
        const $panel = $quoteTabsSection.find(`[data-quote-panel="${$opener.data('quote-tab')}"]`);

        $opener.on('click', () => {
          if (!$opener.hasClass(ACTIVE_CLASS_NAME)) {
            $opener.addClass(ACTIVE_CLASS_NAME).attr('aria-expanded', true);
            $panel.slideDown(ANIMATION_SPEED).attr('aria-hidden', false);
            $quoteTabsSection.find(`.quote-tabs__nav-button[data-quote-tab="${$opener.data('quote-tab')}"]`)
              .addClass(ACTIVE_CLASS_NAME).attr('aria-expanded', true);
          } else {
            $opener.removeClass(ACTIVE_CLASS_NAME).attr('aria-expanded', false);
            $panel.slideUp(ANIMATION_SPEED).attr('aria-hidden', true);
            $quoteTabsSection.find(`.quote-tabs__nav-button[data-quote-tab="${$opener.data('quote-tab')}"]`)
              .removeClass(ACTIVE_CLASS_NAME).attr('aria-expanded', false);
          }
        });
      });

      const resetColumnPosition = ($column) => {
        $column.removeAttr('style');
      }

      const setColumnPosition = ($window, $parallaxColumnsBox, $column) => {
        const heightDelta = $column.outerHeight() - $parallaxColumnsBox.outerHeight();
        let scrollDelta = ($window.scrollTop() + ($window.height() * 0.1)) - $parallaxColumnsBox.offset().top;
        scrollDelta = scrollDelta < 20 ? 0 : scrollDelta < $parallaxColumnsBox.outerHeight() ? scrollDelta : $parallaxColumnsBox.outerHeight();
        scrollDelta = scrollDelta / $parallaxColumnsBox.outerHeight();
        $column.css('transform', `translateY(${(heightDelta * (scrollDelta * 0.4))}px)`);
      }

      $quoteTabsSection.each((index, elem) => {
        const $parallaxColumnsBox = $(elem).closest('.quote-tabs');
        const $column = $(elem).find('.quote-tabs__intro-img-wrapper > img');


        setColumnPosition($(window), $parallaxColumnsBox, $column);

        $(window).on('scroll', () => {
          if ($(window).width() >= 700) {
            setColumnPosition($(window), $parallaxColumnsBox, $column);
          }
          else {
            resetColumnPosition($column);
          }
        });
      });

      $quoteTabsToggle.each((index, opener) => {
        const $opener = $(opener);
        const $panel = $quoteTabsSection.find(`[data-quote-panel="${$opener.data('quote-tab')}"]`);

        $opener.on('click', () => {
          $panels.fadeOut(ANIMATION_SPEED).attr('aria-hidden', true);

          resizeContentBox();

          setTimeout(() => {
            $quoteTabsMobileToggle.removeClass(ACTIVE_CLASS_NAME).attr('aria-expanded', false);
            $quoteTabsToggle.removeClass(ACTIVE_CLASS_NAME).attr('aria-expanded', false);
            setQuoteBarPos($opener.data('quote-tab'));

            // fade in new image
            $('.quote-tabs__intro-img-wrapper > img').removeClass('js-active');
            $('.quote-tabs__intro-img-wrapper > img').eq($opener.data('quote-tab')).addClass('js-active');
            
            $quoteTabsSection.find(`[data-quote-tab="${$opener.data('quote-tab')}"]`)
              .addClass(ACTIVE_CLASS_NAME).attr('aria-expanded', true);
          }, ANIMATION_SPEED);

          setTimeout(() => {
            $panel.fadeIn(ANIMATION_SPEED).attr('aria-hidden', false);
          }, ANIMATION_SPEED * 2);
        });

        // $opener.on('click', () => {
        //   $panels.fadeOut(ANIMATION_SPEED).attr('aria-hidden', true);

        //   setTimeout(() => {
        //     $quoteTabsMobileToggle.removeClass(ACTIVE_CLASS_NAME).attr('aria-expanded', false);
        //     $quoteTabsToggle.removeClass(ACTIVE_CLASS_NAME).attr('aria-expanded', false);

        //     $quoteTabsSection.find(`[data-quote-tab="${$opener.data('quote-tab')}"]`)
        //       .addClass(ACTIVE_CLASS_NAME).attr('aria-expanded', true);
        //   }, ANIMATION_SPEED);

        //   setTimeout(() => {
        //     $panel.fadeIn(ANIMATION_SPEED).attr('aria-hidden', false);
        //   }, ANIMATION_SPEED * 2);
        // });
      });

      if ($(window).width() > 699) {
        $quoteTabsToggle.first().trigger('click');
      }

      $(window).on('resize', () => {
        if ($(window).width() > 699 && !$quoteTabsSection.find(`.${ACTIVE_CLASS_NAME}`).length) {
          $quoteTabsToggle.first().trigger('click');
        }
      });

      $(window).on('load resize', () => {
        resizeContentBox();
      });
    });
  }

  return {
    init,
  };
})();

export default quoteTabs;
