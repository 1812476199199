const parallaxGallery = (() => {
  const IS_EXPANDED_CLASSNAME = 'is-expanded';

  const initGalleryMobileToggle = ($gallery) => {
    const $toggleButton = $gallery.find('.collapse-box__toggle');
    const $collapseBoxInner = $gallery.find('.collapse-box__inner');
    const $collapseBoxWrapper = $gallery.find('.collapse-box__wrapper');

    $collapseBoxWrapper.css('--inner-content-height', `${$collapseBoxInner.outerHeight()}px`);

    $(window).on('resize', () => {
      $collapseBoxWrapper.css('--inner-content-height', `${$collapseBoxInner.outerHeight()}px`);
    });

    $toggleButton.on('click', () => {
      if (!$collapseBoxWrapper.hasClass(IS_EXPANDED_CLASSNAME)) {
        $collapseBoxWrapper.toggleClass(IS_EXPANDED_CLASSNAME);
        $toggleButton.text('Show less');
      } else {
        $collapseBoxWrapper.removeClass(IS_EXPANDED_CLASSNAME);
        $toggleButton.text('Show more')
      }
    });
  }

  const setColumnPosition = ($window, $parallaxColumnsBox, $column) => {
    const heightDelta = $column.outerHeight() - $parallaxColumnsBox.outerHeight();
    let scrollDelta = ($window.scrollTop() + ($window.height() * 0.85)) - $parallaxColumnsBox.offset().top;
    scrollDelta = scrollDelta < 20 ? 0 : scrollDelta < $parallaxColumnsBox.outerHeight() ? scrollDelta : $parallaxColumnsBox.outerHeight();
    scrollDelta = scrollDelta / $parallaxColumnsBox.outerHeight();
    $column.css('transform', `translateY(${(heightDelta * scrollDelta) * -1}px)`);
  }

  const initParallaxGallery = ($gallery, $window) => {
    const $galleryIntro = $gallery.find('.parallax-gallery__intro');
    const $parallaxColumnsBox = $gallery.find('.parallax__columns');
    const $columns = $gallery.find('.parallax__column');

    $columns.each((index, column) => {
      const $column = $(column);

      setColumnPosition($window, $parallaxColumnsBox, $column);

      $window.on('scroll', () => {
        if (($window.scrollTop() + ($window.height()/2)) > ($galleryIntro.offset().top + $galleryIntro.outerHeight())) {
          setColumnPosition($window, $parallaxColumnsBox, $column);
        }
      });
    });
  }

  const init = () => {
    const $galleries = $('.parallax-gallery__section');
    const $window = $(window);

    $galleries.each((index, gallery) => {
      initGalleryMobileToggle($(gallery));
      initParallaxGallery($(gallery), $window);
    });
  }

  return {
    init,
  }
})();

export default parallaxGallery;