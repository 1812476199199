const alertNotifications = (() => {
  const ANIMATION_SPEED = 300;
  
  const init = () => {
    const $alerts = $('.alert');

    if(!$alerts.length) {
      return;
    }

    $alerts.each((index, alert) => {
      const $alert = $(alert);
      const $closeButton = $alert.find('.alert__close-button');

      $closeButton.on('click', () => {
        $alert.slideUp(ANIMATION_SPEED);
      });
    });
  }

  return {
    init,
  }
})();

export default alertNotifications;